[data-v-4a61b025] .cus-dialog .cus-treeselect {
  font-size: var(--rootFontSize);
}
[data-v-4a61b025] .cus-dialog .cus-treeselect .vue-treeselect__control {
    height: 36px;
    line-height: 36px;
}
[data-v-4a61b025] .cus-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      margin-bottom: 3px;
}
[data-v-4a61b025] .cus-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__single-value,[data-v-4a61b025] .cus-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__placeholder {
      line-height: 36px;
}
[data-v-4a61b025] .cus-dialog .cus-treeselect .vue-treeselect__control .vue-treeselect__multi-value {
      line-height: 26px;
}
