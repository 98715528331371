.archive-wrapper[data-v-2b221d08] {
  width: 100%;
  height: 100%;
}
.archive-wrapper .archive-title[data-v-2b221d08] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #f5f6fd;
    padding: 0 1vw;
}
.archive-wrapper .archive-title .title-wrapper[data-v-2b221d08] {
      font-size: 1.3vw;
      font-weight: 700;
}
.archive-wrapper .archive-title .search-wrapper[data-v-2b221d08] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 50px;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.archive-wrapper .archive-title .search-wrapper .el-button[data-v-2b221d08] {
        margin-left: 14px;
}
.archive-wrapper .archive-content[data-v-2b221d08] {
    width: 100%;
    height: calc(100% - 70px);
    background-color: #f5f6fd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0.75vw 1vw;
    margin-top: 10px;
    overflow-y: auto;
}
.archive-wrapper .archive-content .mian[data-v-2b221d08] {
      width: 100%;
}
.archive-wrapper .archive-content .mian h1[data-v-2b221d08] {
        width: 100%;
        height: 1.4vw;
}
.archive-wrapper .archive-content .mian h1 img[data-v-2b221d08] {
          width: 1.3vw;
          height: 1.3vw;
          margin-right: 10px;
}
.archive-wrapper .archive-content .mian h1 span[data-v-2b221d08] {
          vertical-align: bottom;
          font-size: 1.3vw;
          font-weight: 550;
}
.archive-wrapper .archive-content .mian ul[data-v-2b221d08] {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-bottom: 1vw;
}
.archive-wrapper .archive-content .mian ul li[data-v-2b221d08] {
          font-family: Source Han Sans CN;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
          padding: 1.3vw 1.1vw 0vw 1.1vw;
          width: 23.5%;
          margin-left: 1vw;
          height: 9vw;
          -webkit-box-shadow: 0vw 0vw 10px 0vw rgba(165, 192, 237, 0.6);
                  box-shadow: 0vw 0vw 10px 0vw rgba(165, 192, 237, 0.6);
          border-radius: 10px;
          position: relative;
          margin-bottom: 1vw;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
}
.archive-wrapper .archive-content .mian ul li[data-v-2b221d08]:hover {
            -webkit-transform: translateY(-2px);
                    transform: translateY(-2px);
            -webkit-box-shadow: 4px 4px 10px 2px rgba(165, 192, 237, 0.9);
                    box-shadow: 4px 4px 10px 2px rgba(165, 192, 237, 0.9);
}
.archive-wrapper .archive-content .mian ul li .li-span[data-v-2b221d08] {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 1vw;
            padding: 0.1vw 0.7vw;
            border-radius: 0 10px 0 10px;
}
.archive-wrapper .archive-content .mian ul li .li-span-edit[data-v-2b221d08] {
            color: #609af7;
            background-color: #fff;
}
.archive-wrapper .archive-content .mian ul li .li-span-pub[data-v-2b221d08] {
            color: #58e393;
            background-color: #fff;
}
.archive-wrapper .archive-content .mian ul li .li-span-danger[data-v-2b221d08] {
            color: #609af7;
            background-color: #c2e2ff;
}
.archive-wrapper .archive-content .mian ul li .li-title[data-v-2b221d08] {
            height: 5vw;
            font-size: 1.3vw;
            font-weight: bold;
            color: #fff;
            word-wrap: break-word;
}
.archive-wrapper .archive-content .mian ul li .li-subtitle[data-v-2b221d08] {
            display: none;
            margin-top: 1vw;
            height: 1.2vw;
            font-size: 1.2vw;
            font-weight: 400;
            color: #1f346c;
}
.archive-wrapper .archive-content .mian ul li .li-subtitle-printf[data-v-2b221d08] {
            display: none;
            margin-top: 1vw;
            height: 1.2vw;
            font-size: 1vw;
            font-weight: 400;
            color: #1f346c;
}
.archive-wrapper .archive-content .mian ul li .li-button[data-v-2b221d08] {
            height: 1.5vw;
            width: 100%;
            margin-top: 0.2vw;
}
.archive-wrapper .archive-content .mian ul li .li-button img[data-v-2b221d08] {
              width: 1.2vw;
              cursor: pointer;
}
.archive-wrapper .archive-content .mian ul li .li-button .img-left[data-v-2b221d08]:nth-child(n + 2) {
              margin-left: 1vw;
}
.archive-wrapper .archive-content .mian .data-empty[data-v-2b221d08] {
        height: 200px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        background: #f5f6fd;
        border: 1px solid #eee;
}
.archive-wrapper .archive-content .mian .data-empty img[data-v-2b221d08] {
          height: 30px;
}
.archive-wrapper .archive-content .empty-img[data-v-2b221d08] {
      width: 100%;
      height: 100%;
      background-color: #f5f6fd;
      overflow: auto;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
.archive-wrapper .archive-content .empty-img img[data-v-2b221d08] {
        display: block;
        width: 200px;
}
.archive-wrapper .archive-content .empty-img p[data-v-2b221d08] {
        z-index: 1;
        text-align: center;
        background-color: #f5f6fd;
        color: #666;
        font-size: 24px;
        font-weight: 600;
}
.archive-wrapper .bottom-line[data-v-2b221d08] {
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 14px 0;
}
